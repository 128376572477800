<template>
  <div>
    <vx-card :title="`${id ? $t('action.edit') : $t('action.create')} ${$t('relatorio_dinamico')}`">
      <vs-input v-model="report.name" :label="$t('relatorios_dinamicos_nome')" class="mb-4 w-full" />
      <vs-textarea v-model="report.description" :label="$t('relatorios_dinamicos_descricao')" class="mb-4" />
      <vs-textarea v-model="report.sql_query" :label="$t('relatorios_dinamicos_sql_query')" class="mb-4" />

      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button
            class="float-right"
            color="primary"
            type="border"
            @click="cancel"
            >{{ $t('action.cancel') }}</vs-button
          >
          <vs-button
            v-permission="'dynamic_reports.create'"
            :disabled="!validateForm"
            class="float-right mr-2"
            @click="create"
            >{{ $t('action.save') }}</vs-button
          >
        </div>
      </div>

      <vs-divider class="mt-4" v-if="id">{{ $t('relatorios_dinamicos_defina_os_filtros') }}</vs-divider>

      <!-- div filters -->
      <div class="vx-row" v-if="id && report.filters.length > 0">
        <div v-for="(filter, index) in report.filters" :key="index" class="vx-col w-full mb-4 flex items-center">
          <vs-input v-model="filter.field" label="Field" class="mb-2 mr-2 flex-1" />
          <vs-input v-model="filter.label" label="Label" class="mb-2 mr-2 flex-1" />
          <vs-select v-model="filter.type" label="Tipo de Dado" class="mb-2 mr-2 flex-1">
            <vs-select-item value="integer" text="Integer" />
            <vs-select-item value="string" text="String" />
            <vs-select-item value="date" text="Date" />
            <vs-select-item value="datetime" text="Datetime" />
            <vs-select-item value="boolean" text="Booleano" />
            <vs-select-item value="numeric" text="Numeric" /> <!-- Novo tipo de filtro -->
            <!-- Add more types as needed -->
          </vs-select>
          <vs-checkbox v-model="filter.required" class="mb-4 mr-4 mt-auto">{{ $t('obrigatorio') }}</vs-checkbox>
          <vs-button @click="removeFilter(index)" color="danger" type="border" class="mb-2 mt-auto">
            <feather-icon icon="TrashIcon" svgClasses="h-4 w-4 mr-2" />
            {{ $t('remover') }}
          </vs-button>
        </div>
      </div>

      <div class="w-full flex gap-2 justify-end" v-if="id">
        <vs-button @click="addFilter" color="success" type="border">
          {{ $t('relatorios_dinamicos_adicionar_filtro') }}
        </vs-button>
      </div>
    </vx-card>
  </div>
</template>

<script>
import DynamicReportService from '@/services/api/DynamicReportService'

export default {
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      report: {
        name: '',
        description: '',
        sql_query: '',
        filters: []
      }
    }
  },
  methods: {
    validateForm() {
      return (
        !this.isEmpty(this.report.name) &&
        !this.isEmpty(this.report.sql_query) &&
        this.validateFilters()
      )
    },
    validateFilters() {
      const filterPlaceholders = this.report.sql_query.match(/:\w+/g) || []
      const definedFilters = this.report.filters.map(filter => filter.field)

      for (const placeholder of filterPlaceholders) {
        const filterName = placeholder.substring(1)
        if (!definedFilters.includes(filterName)) {
          this.notifyError(
            this.$vs,
            `O filtro '${filterName}' não está definido.`
          )
          return false
        }
      }
      return true
    },
    create() {
      if (!this.isEmpty(this.report.name) || !this.isEmpty(this.report.sql_query)) {
        if (this.id && !this.validateFilters()) {
          return
        }
      } else {
        return
      }

      this.clearErrors(this.$validator)
      this.$vs.loading()

      const reportData = { ...this.report }
      if (this.id) {
        reportData.id = this.id
      }

      // Remove filters if they are null or empty
      if (!reportData.filters || reportData.filters.length === 0) {
        delete reportData.filters
      }

      this.service.createOrUpdate(reportData).then(response => {
        this.$vs.loading.close()
        this.$router.push(`/dynamic_reports/${response.id}/edit`)
        this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
      }).catch(error => {
        this.$vs.loading.close()
        this.notifyError(
          this.$vs,
          this.$t('nao-foi-possivel-realizar-esta-operacao')
        )
      })
    },
    cancel() {
      this.report = { name: '', description: '', sql_query: '', filters: [] }
    },
    getData(id) {
      this.$vs.loading()
      this.service.read(id).then(
        data => {
          this.report.name = data.name
          this.report.description = data.description
          this.report.sql_query = data.sql_query
          this.report.filters = JSON.parse(data.filters) || [] // Ensure filters are loaded
          this.$vs.loading.close()
        },
        error => {
          this.$vs.loading.close()
        }
      )
    },
    addFilter() {
      this.report.filters.push({ label: '', field: '', type: 'string', required: false })
    },
    removeFilter(index) {
      this.report.filters.splice(index, 1)
    }
  },
  beforeMount() {
    this.service = DynamicReportService.build(this.$vs)
    if (this.id) {
      this.getData(this.id)
    }
  }
}
</script>
