var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        {
          attrs: {
            title:
              (_vm.id ? _vm.$t("action.edit") : _vm.$t("action.create")) +
              " " +
              _vm.$t("relatorio_dinamico"),
          },
        },
        [
          _c("vs-input", {
            staticClass: "mb-4 w-full",
            attrs: { label: _vm.$t("relatorios_dinamicos_nome") },
            model: {
              value: _vm.report.name,
              callback: function ($$v) {
                _vm.$set(_vm.report, "name", $$v)
              },
              expression: "report.name",
            },
          }),
          _c("vs-textarea", {
            staticClass: "mb-4",
            attrs: { label: _vm.$t("relatorios_dinamicos_descricao") },
            model: {
              value: _vm.report.description,
              callback: function ($$v) {
                _vm.$set(_vm.report, "description", $$v)
              },
              expression: "report.description",
            },
          }),
          _c("vs-textarea", {
            staticClass: "mb-4",
            attrs: { label: _vm.$t("relatorios_dinamicos_sql_query") },
            model: {
              value: _vm.report.sql_query,
              callback: function ($$v) {
                _vm.$set(_vm.report, "sql_query", $$v)
              },
              expression: "report.sql_query",
            },
          }),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "float-right",
                    attrs: { color: "primary", type: "border" },
                    on: { click: _vm.cancel },
                  },
                  [_vm._v(_vm._s(_vm.$t("action.cancel")))]
                ),
                _c(
                  "vs-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: "dynamic_reports.create",
                        expression: "'dynamic_reports.create'",
                      },
                    ],
                    staticClass: "float-right mr-2",
                    attrs: { disabled: !_vm.validateForm },
                    on: { click: _vm.create },
                  },
                  [_vm._v(_vm._s(_vm.$t("action.save")))]
                ),
              ],
              1
            ),
          ]),
          _vm.id
            ? _c("vs-divider", { staticClass: "mt-4" }, [
                _vm._v(
                  _vm._s(_vm.$t("relatorios_dinamicos_defina_os_filtros"))
                ),
              ])
            : _vm._e(),
          _vm.id && _vm.report.filters.length > 0
            ? _c(
                "div",
                { staticClass: "vx-row" },
                _vm._l(_vm.report.filters, function (filter, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "vx-col w-full mb-4 flex items-center",
                    },
                    [
                      _c("vs-input", {
                        staticClass: "mb-2 mr-2 flex-1",
                        attrs: { label: "Field" },
                        model: {
                          value: filter.field,
                          callback: function ($$v) {
                            _vm.$set(filter, "field", $$v)
                          },
                          expression: "filter.field",
                        },
                      }),
                      _c("vs-input", {
                        staticClass: "mb-2 mr-2 flex-1",
                        attrs: { label: "Label" },
                        model: {
                          value: filter.label,
                          callback: function ($$v) {
                            _vm.$set(filter, "label", $$v)
                          },
                          expression: "filter.label",
                        },
                      }),
                      _c(
                        "vs-select",
                        {
                          staticClass: "mb-2 mr-2 flex-1",
                          attrs: { label: "Tipo de Dado" },
                          model: {
                            value: filter.type,
                            callback: function ($$v) {
                              _vm.$set(filter, "type", $$v)
                            },
                            expression: "filter.type",
                          },
                        },
                        [
                          _c("vs-select-item", {
                            attrs: { value: "integer", text: "Integer" },
                          }),
                          _c("vs-select-item", {
                            attrs: { value: "string", text: "String" },
                          }),
                          _c("vs-select-item", {
                            attrs: { value: "date", text: "Date" },
                          }),
                          _c("vs-select-item", {
                            attrs: { value: "datetime", text: "Datetime" },
                          }),
                          _c("vs-select-item", {
                            attrs: { value: "boolean", text: "Booleano" },
                          }),
                          _c("vs-select-item", {
                            attrs: { value: "numeric", text: "Numeric" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-checkbox",
                        {
                          staticClass: "mb-4 mr-4 mt-auto",
                          model: {
                            value: filter.required,
                            callback: function ($$v) {
                              _vm.$set(filter, "required", $$v)
                            },
                            expression: "filter.required",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("obrigatorio")))]
                      ),
                      _c(
                        "vs-button",
                        {
                          staticClass: "mb-2 mt-auto",
                          attrs: { color: "danger", type: "border" },
                          on: {
                            click: function ($event) {
                              return _vm.removeFilter(index)
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            attrs: {
                              icon: "TrashIcon",
                              svgClasses: "h-4 w-4 mr-2",
                            },
                          }),
                          _vm._v(" " + _vm._s(_vm.$t("remover")) + " "),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.id
            ? _c(
                "div",
                { staticClass: "w-full flex gap-2 justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "success", type: "border" },
                      on: { click: _vm.addFilter },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("relatorios_dinamicos_adicionar_filtro")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }